import {
  ReactNode,
  useMemo,
  useState,
  MouseEvent,
  useEffect,
  Key,
  Fragment,
} from 'react';
import {
  Select,
  List,
  Row,
  Col,
  Tag,
  Typography,
  PaginationProps,
  Button,
} from 'antd';
import { PlusOutlined, DeleteOutlined, UndoOutlined } from '@ant-design/icons';
import { isNil, equals, isNotNil, isEmpty, difference, omit } from 'ramda';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';

import { InputListProps, BaseOption, ItemRenderType } from './interfaces';
import { Container, AddButton, RemoveItemWrapper, StyledList } from './styleds';

const { Option: SelectOption } = Select;
const { Text } = Typography;
const { Item: ListItem } = List;

const showTotal: PaginationProps['showTotal'] = (total) => (
  <Text>{`${total} Members`}</Text>
);

const InputList = <Option extends BaseOption = any>(
  props: InputListProps<Option>,
) => {
  const {
    value: extValue,
    onChange,
    onSearch,
    loading,
    options,
    dataSource: extDataSource,
    total,
    tagRender,
    itemRender,
    optionRender,
    onPaginationChange,
    buttonText = 'Add',
    buttonIcon = <PlusOutlined />,
    tagKey = 'value',
    itemKey = 'value',
    onDataSourceRemove,
    showNewTag = true,
    transformSelectedValue,
    transformNewValue,
  } = props;
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [selectedOptionMap, setSelectedOptionMap] = useState<{
    [key: string]: Option;
  }>({});
  const [value, setValue] = useState(extValue);
  const [dataSource, setDataSource] = useState(extDataSource);
  const [removeValue, setRemoveValue] = useState<{
    [value: string]: Option;
  }>({});

  const handleSearch = (text: string) => {
    const regexp = new RegExp(`^${text}`, 'i');
    const excludes = dataSource?.filter((item) =>
      regexp.test(`${item[tagKey]}`),
    );
    setSearchText(text);
    onSearch?.(text, excludes);
  };

  const handleSelectChange = async (value: string[]) => {
    if (value.length < selectedValues.length) {
      const removed = difference(selectedValues, value);
      setSelectedValues(value);
      setSelectedOptionMap((prev) => omit(removed, prev));
    } else {
      handleSearch('');

      if (!Array.isArray(options) || isEmpty(options)) return;
      const last = value[value.length - 1];
      const option = options?.find((option) => option.value === last);

      if (isNil(option)) return;
      const next = (await transformSelectedValue?.(last, option)) || [
        [last, option],
      ];

      if (isEmpty(next)) return;
      setSelectedValues([...value.slice(0, -1), ...next.map(([key]) => key)]);
      setSelectedOptionMap((prev) => ({
        ...prev,
        ...Object.fromEntries(next),
      }));
    }
  };

  const handleAddClick = async () => {
    const newValues =
      (await transformNewValue?.(Object.values(selectedOptionMap))) ||
      Object.values(selectedOptionMap);
    const next = {
      add: [...newValues, ...(value?.['add'] || [])],
      remove: value?.remove || [],
    };
    setValue(next);
    onChange?.(next);
    setSelectedValues([]);
    setSelectedOptionMap({});
    setDataSource([...next.add, ...(extDataSource || [])]);
  };

  const handleBlur = () => {
    handleSearch('');
  };

  const handleRemoveClick = (option: Option, index: number) => {
    const addLength = value?.add.length || 0;
    if (index >= addLength) {
      onDataSourceRemove?.(option, index);
      setRemoveValue((prev) => ({ ...prev, [option.value]: option }));
      const next = {
        add: value?.add || [],
        remove: [...(value?.remove || []), option],
      };
      setValue(next);
      onChange?.(next);
    } else {
      const next = {
        add: [
          ...(value?.add || []).slice(0, index),
          ...(value?.add || []).slice(index + 1),
        ],
        remove: value?.remove || [],
      };
      setValue(next);
      onChange?.(next);
      setDataSource([...next.add, ...(extDataSource || [])]);
    }
  };

  const handleUndoClick = (option: Option) => {
    const { [option.value]: undoValue, ...otherValue } = removeValue;
    setRemoveValue(otherValue);
    const next = {
      add: value?.add || [],
      remove: Object.values(otherValue),
    };
    setValue(next);
    onChange?.(next);
  };

  const defaultTagRender = (props: CustomTagProps) => {
    const { value, closable, onClose } = props;

    const preventMouseDown = (event: MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    if (isNil(value)) return <Fragment />;
    return isNotNil(selectedOptionMap[value as string]) ? (
      <Tag closable={closable} onClose={onClose} onMouseDown={preventMouseDown}>
        {selectedOptionMap[value as string][tagKey] as ReactNode}
      </Tag>
    ) : (
      <Fragment />
    );
  };

  const OptionNodes = useMemo(
    () =>
      isNil(optionRender)
        ? options?.map((option) => (
            <SelectOption key={option.value} value={option.value}>
              {option.value}
            </SelectOption>
          ))
        : options?.map((option, index) => (
            <SelectOption key={option.value} value={option.value}>
              {optionRender(option, index)}
            </SelectOption>
          )),
    [options, optionRender],
  );

  const listItemRender = (item: Option, index: number) => {
    const key = item[itemKey] as Key;
    const addLength = value?.add.length || 0;
    return removeValue[item.value] ? (
      <ListItem
        key={key}
        actions={[
          <Button
            type="link"
            icon={<UndoOutlined />}
            onClick={() => handleUndoClick(item)}
          />,
        ]}
      >
        <RemoveItemWrapper>
          {itemRender?.(item, index, 'remove')}
        </RemoveItemWrapper>
        <Tag color="red">Remove</Tag>
      </ListItem>
    ) : (
      <ListItem
        key={key}
        actions={[
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => handleRemoveClick(item, index)}
          />,
        ]}
      >
        {itemRender?.(item, index, index < addLength ? 'new' : 'origin')}
        {index < addLength && showNewTag && <Tag color="gold">New</Tag>}
      </ListItem>
    );
  };

  useEffect(() => {
    if (equals(extValue, value)) return;
    setValue(extValue);
  }, [extValue]);

  useEffect(() => {
    setDataSource([...(value?.add ?? []), ...(extDataSource ?? [])]);
  }, [extDataSource]);

  return (
    <div>
      <Container direction="vertical" size="small">
        <Row gutter={8}>
          <Col flex="1">
            <Select
              mode="multiple"
              suffixIcon={null}
              filterOption={false}
              notFoundContent={null}
              loading={loading}
              tagRender={tagRender || defaultTagRender}
              value={selectedValues}
              onChange={handleSelectChange}
              searchValue={searchText}
              onSearch={handleSearch}
              onBlur={handleBlur}
            >
              {OptionNodes}
            </Select>
          </Col>
          <Col flex="0 0 auto">
            <AddButton
              disabled={selectedValues.length === 0}
              icon={buttonIcon}
              onClick={handleAddClick}
            >
              {buttonText}
            </AddButton>
          </Col>
        </Row>
        <StyledList
          pagination={{
            align: 'end',
            total,
            showTotal,
            onChange: onPaginationChange,
          }}
          dataSource={dataSource}
          renderItem={listItemRender}
        />
      </Container>
    </div>
  );
};

export default InputList;
export type { ItemRenderType };
